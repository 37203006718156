import React, { useState, useRef, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Mapbox from '../../../mapbox'

import Sidetab from './Sidetab'
import Overview from './map_contents/Overview'
import FilterMap from './FilterMap'

import Calculate from '../../../util/calculate';
import getProvinceName from '../../../util/getProvinceName';
import api from '../../../util/api';

import '../../index.css';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const FullMap = (props) => {
  const [initData, setInitData] = useState(null)

  const [isLoading, setLoading] = useState(true)
  const [open, setOpen] = useState(true)
  const [openTop10, setOpenTop10] = useState(true)
  const [tabvalue, setTabValue] = React.useState(0);

  const [datamap, setDataMap] = useState([{ id: 'border', layer: 'country', data: [] }])
  const [data, setData] = useState([])
  const [province, setProvince] = useState([])
  const [notic, setNotic] = useState([])

  const [filter, setFilter] = React.useState({
    view: 'country',
    year: "2566",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
    page: 1,
    tap: 1,
    tuntype: "all"
  })

  useEffect(async () => {
    let dataSummaryAllyear = await api.getInitData("fund");
    console.log("dataSummaryAllyear", dataSummaryAllyear.data)
    console.log("dataSummaryAllyear", dataSummaryAllyear.data.reduce((a, b) => a + b.school, 0))
    console.log("dataSummaryAllyear", dataSummaryAllyear.data.filter((a) => a.school !== 0))
    setInitData(dataSummaryAllyear.data);
  }, [])

  const is_Click = useRef(false);
  const [data_mapcolor] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])

  useEffect(async () => {
    if (initData) {
      let res_profile = await api.getProfileDb()
      if (res_profile?.profile?.profile?.provinceId) {
        setData(initData)
        setLoading(false)
        let _provincename = getProvinceName(res_profile?.profile?.profile?.provinceId)
        setProvince(_provincename)
        setTimeout(() => { setFilter({ ...filter, view: 'province', province_name: _provincename }) }, 1000)
      }
    }
  }, [initData])


  useEffect(async () => {
    if (initData) {
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        layer: filter.view
      }, convertMapContent(filter.view, initData, filter.tuntype))
      // if(filter['province_name'] === 'ไม่ระบุ' || filter['city_name'] === 'ไม่ระบุ' || filter['tumbon_name'] === 'ไม่ระบุ' ) return
      setDataMap([{ id: 'border', layer: filter.view, data: response.data }])
    }
  }, [filter, initData])

  let tab_content = [
    {
      title: 'ภาพรวม',
      content: <Overview />
    },
    {
      title: 'ภาพรวม',
      content: (
        <p className='text-center text-base lg:text-lg font-baijam'>
          โครงการทุนเสมอภาคได้เริ่มช่วยเหลือนักเรียนตั้งแต่ปีการศึกษา 2566 จนถึงปัจจุบัน <br />
          โดยมีกลุ่มเป้าหมายเป็นเด็กนักเรียนในระดับการศึกษาผู้ขาดแคลนทุนทรัพย์ <br />
          โดยให้ความช่วยเหลือกลุ่มเป้าหมายไปแล้วกว่า 5,000,000 คน ครอบคลุมโรงเรียน 30,000 โรงเรียน ใน 6 สังกัด
        </p>
      )
    },
  ]


  // let res_profile = await api.getProfileDb()
  // let profile_data = res_profile.profile.profile

  const convertMapContent = (layer, data, tuntype) => {
    let res = []
    const province = [...new Set(data.map(item => item.province_name))].map(item => { return { province: item, total: 0, eef_help: 0 } });
    const city = [...new Set(data.map(item => item.province_name + '_' + item.city_name))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], total: 0, eef_help: 0 } });
    const tumbon = [...new Set(data.map(item => item.province_name + '_' + item.city_name + '_' + item.tumbon_name))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], total: 0, eef_help: 0 } });
    data.forEach((item) => {
      let total = (item.total)
      let eef_help = 0
      if (filter.page === 1) {
        if (tuntype === 'all' || !tuntype) {
          eef_help = (item.exjon + item.krt + item.vec + item.tps + item.target_group)
        } else {
          eef_help = item[tuntype]
        }
      } else {
        if (filter.tap === 1) {
          if (tuntype === 'all' || !tuntype) {
            eef_help = (item.abe + item.province20 + item.plan_teaher)
          } else {
            eef_help = item[tuntype]
          }
        } else {
          if (tuntype === 'all' || !tuntype) {
            eef_help = (item.tsqp)
          } else {
            eef_help = item[tuntype]
          }
        }
      }
      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        !province[index]['total'] ? province[index]['total'] = total : province[index]['total'] += total
        !province[index]['eef_help'] ? province[index]['eef_help'] = eef_help : province[index]['eef_help'] += eef_help

        !province[index]['exjon'] ? province[index]['exjon'] = item.exjon : province[index]['exjon'] += item.exjon
        !province[index]['krt'] ? province[index]['krt'] = item.krt : province[index]['krt'] += item.krt
        !province[index]['vec'] ? province[index]['vec'] = item.vec : province[index]['vec'] += item.vec
        !province[index]['tps'] ? province[index]['tps'] = item.tps : province[index]['tps'] += item.tps
        !province[index]['target_group'] ? province[index]['target_group'] = item.target_group : province[index]['target_group'] += item.target_group
        !province[index]['abe'] ? province[index]['abe'] = item.abe : province[index]['abe'] += item.abe
        !province[index]['province20'] ? province[index]['province20'] = item.province20 : province[index]['province20'] += item.province20
        !province[index]['plan_teaher'] ? province[index]['plan_teaher'] = item.plan_teaher : province[index]['plan_teaher'] += item.plan_teaher
        !province[index]['tsqp'] ? province[index]['tsqp'] = item.tsqp : province[index]['tsqp'] += item.tsqp
        res = province
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (index !== -1) {
          !city[index]['total'] ? city[index]['total'] = total : city[index]['total'] += total
          !city[index]['eef_help'] ? city[index]['eef_help'] = eef_help : city[index]['eef_help'] += eef_help
          !city[index]['exjon'] ? city[index]['exjon'] = item.exjon : city[index]['exjon'] += item.exjon
          !city[index]['krt'] ? city[index]['krt'] = item.krt : city[index]['krt'] += item.krt
          !city[index]['vec'] ? city[index]['vec'] = item.vec : city[index]['vec'] += item.vec
          !city[index]['tps'] ? city[index]['tps'] = item.tps : city[index]['tps'] += item.tps
          !city[index]['target_group'] ? city[index]['target_group'] = item.target_group : city[index]['target_group'] += item.target_group
          !city[index]['abe'] ? city[index]['abe'] = item.abe : city[index]['abe'] += item.abe
          !city[index]['province20'] ? city[index]['province20'] = item.province20 : city[index]['province20'] += item.province20
          !city[index]['plan_teaher'] ? city[index]['plan_teaher'] = item.plan_teaher : city[index]['plan_teaher'] += item.plan_teaher
          !city[index]['tsqp'] ? city[index]['tsqp'] = item.tsqp : city[index]['tsqp'] += item.tsqp
        }
        res = city
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === item.tumbon_name)
        if (index !== -1) {
          if (item.city_name === 'เขตพระนคร') console.log("total", item['total'])
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['eef_help'] ? tumbon[index]['eef_help'] = eef_help : tumbon[index]['eef_help'] += eef_help
          !tumbon[index]['exjon'] ? tumbon[index]['exjon'] = item.exjon : tumbon[index]['exjon'] += item.exjon
          !tumbon[index]['krt'] ? tumbon[index]['krt'] = item.krt : tumbon[index]['krt'] += item.krt
          !tumbon[index]['vec'] ? tumbon[index]['vec'] = item.vec : tumbon[index]['vec'] += item.vec
          !tumbon[index]['tps'] ? tumbon[index]['tps'] = item.tps : tumbon[index]['tps'] += item.tps
          !tumbon[index]['target_group'] ? tumbon[index]['target_group'] = item.target_group : tumbon[index]['target_group'] += item.target_group
          !tumbon[index]['abe'] ? tumbon[index]['abe'] = item.abe : tumbon[index]['abe'] += item.abe
          !tumbon[index]['province20'] ? tumbon[index]['province20'] = item.province20 : tumbon[index]['province20'] += item.province20
          !tumbon[index]['plan_teaher'] ? tumbon[index]['plan_teaher'] = item.plan_teaher : tumbon[index]['plan_teaher'] += item.plan_teaher
          !tumbon[index]['oosc'] ? tumbon[index]['oosc'] = item.oosc : tumbon[index]['oosc'] += item.oosc
          !tumbon[index]['tsqp'] ? tumbon[index]['tsqp'] = item.tsqp : tumbon[index]['tsqp'] += item.tsqp
        }
        res = tumbon
      } else if (layer === 'tumbon') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === item.tumbon_name)
        if (index !== -1) {
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['eef_help'] ? tumbon[index]['eef_help'] = eef_help : tumbon[index]['eef_help'] += eef_help
          !tumbon[index]['exjon'] ? tumbon[index]['exjon'] = item.exjon : tumbon[index]['exjon'] += item.exjon
          !tumbon[index]['krt'] ? tumbon[index]['krt'] = item.krt : tumbon[index]['krt'] += item.krt
          !tumbon[index]['vec'] ? tumbon[index]['vec'] = item.vec : tumbon[index]['vec'] += item.vec
          !tumbon[index]['tps'] ? tumbon[index]['tps'] = item.tps : tumbon[index]['tps'] += item.tps
          !tumbon[index]['target_group'] ? tumbon[index]['target_group'] = item.target_group : tumbon[index]['target_group'] += item.target_group
          !tumbon[index]['abe'] ? tumbon[index]['abe'] = item.abe : tumbon[index]['abe'] += item.abe
          !tumbon[index]['province20'] ? tumbon[index]['province20'] = item.province20 : tumbon[index]['province20'] += item.province20
          !tumbon[index]['plan_teaher'] ? tumbon[index]['plan_teaher'] = item.plan_teaher : tumbon[index]['plan_teaher'] += item.plan_teaher
          !tumbon[index]['tsqp'] ? tumbon[index]['tsqp'] = item.tsqp : tumbon[index]['tsqp'] += item.tsqp
        }
        res = tumbon
      }
    })
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['eef_help'] / element['total'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['eef_help'] / result.map(item => item.eef_help).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })

    let data_color = [
      // { color: '#FF9A7B', value: (average(result) * 1.5), count: 0, text: `มากกว่า ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FFAE93', value: (average(result)), count: 0, text: `${average(result).toFixed(2)}% - ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FECAB5', value: (average(result) / 2), count: 0, text: `${(average(result) / 2).toFixed(2)}% - ${average(result).toFixed(2)}%` },
      // { color: '#FFEEE7', value: (0), count: 0, text: `น้อยกว่า ${(average(result) / 2).toFixed(2)}%` },

      { color: data_mapcolor[0], value: Calculate.Percentile(90, result.map(item => item.eef_help)), count: 0, text: `มาก` },
      { color: data_mapcolor[1], value: Calculate.Percentile(50, result.map(item => item.eef_help)), count: 0, text: `ปานกลาง` },
      { color: data_mapcolor[2], value: Calculate.Percentile(10, result.map(item => item.eef_help)), count: 0, text: `ปานกลาง` },
      { color: data_mapcolor[3], value: 0, count: 0, text: `น้อย` },
      { color: data_mapcolor[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.eef_help)
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const onclickBorder = (layer, filterBorder) => {
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['view'] = 'province'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['view'] = 'city'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === 'country') return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = null
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }


  if (isLoading) {
    return (<Layout Role={['partner_province']} isHideFooter>
      <div className='relative overflow-hidden'>
        <div className="w-[100vw] h-[100vh] flex flex-col justify-center items-center">
          <div className="text-center" role="status">
            <svg className="inline mr-2 w-32 h-32 text-gray-200 animate-spin dark:text-gray-200 fill-[#038967]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
          <div className="text-2xl text-[#038967] font-bold text-center mt-4">  Loading ...</div>
        </div>
      </div>
    </Layout>)
  }

  console.log("datamap", datamap.layer)
  return (
    <Layout Role={['partner_province']} isHideFooter>
      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox
            id={'map-cct'}
            data={datamap}
            lng={99}
            lat={13.4}
            zoom={5.2}
            className="fullmap-cct-container"
            style={"border_clean"}
            openSideTab={open}
            filter={filter}
            notic_title={'จำนวนโรงเรียนในประเทศไทย'}
            onclickBorder={onclickBorder}
          />
          <div className={`absolute top-[90px] left-2 z-1}`}>
            <div className='text-center justify-center mt-2 mx-auto'>
              <button class="bg-[#3ebf6a] hover:bg-[#3ebf6a] border-8 border-[#dcfce7] text-white font-bold p-2 rounded-full" onClick={() => setOpenTop10(!openTop10)}>
                {
                  !openTop10 ?
                    <FilterAltIcon sx={{ fontSize: 32, color: 'white' }} />
                    : <CloseIcon sx={{ fontSize: 32, color: 'white' }} />
                }
              </button>
              {(filter.view === "city") ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> ย้อนกลับ
              </button> : <></>}
            </div>
            <FilterMap
              tuntype={filter.tuntype}
              className={`transform duration-700 ${openTop10 ? 'ml-[0]' : 'ml-[-340px]'}`}
              filter={filter}
              onClickTunTop={async (type) => {
                if (type === 'all') {
                  let response = await onProcessData({}, convertMapContent('country', initData))
                  setDataMap([{ id: 'border', data: response.data, layer: 'country' }])
                } else if (type === 'top') {
                  let response = await onProcessData({}, convertMapContent('country', initData))
                  let sortdata = response.data
                    .filter(item => item.province !== '-' && item.province !== '' && item.province !== 'ค่าว่าง' && item.province !== 'ไม่ระบุ' && item.province !== null)
                    .sort((a, b) => b.percent - a.percent).slice(0, 10)
                  setDataMap([{ id: 'border', data: sortdata, layer: 'country' }])
                } else if (type === 'bottom') {
                  let response = await onProcessData({}, convertMapContent('country', initData))
                  let sortdata = response.data
                    .filter(item => item.province !== '-' && item.province !== '' && item.province !== 'ค่าว่าง' && item.province !== 'ไม่ระบุ' && item.province !== null)
                    .sort((a, b) => a.percent - b.percent).slice(0, 10)
                  setDataMap([{ id: 'border', data: sortdata, layer: 'country' }])
                }
              }}
              onChangeTunType={async (value) => {
                setFilter({ ...filter, tuntype: value })
              }}
            />
          </div>
          <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>สัดส่วนคนที่ได้รับทุน</span>
              <div className='flex'>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                  </div>
                  <div class="font-bold text-left">
                    มาก
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                  </div>
                  <div class="font-bold text-right">
                    น้อย
                  </div>
                </div>
                <div className='ml-4  flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                  </div>
                  <div class="font-bold text-center">
                    ไม่มีข้อมูล
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidetab
          filter={filter}
          province={province}
          data={data}
          tab={tab_content}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          handleChangeTab={handleChangeTab}
          updatePageAndTap={(val, type) => {
            let _filter = { ...filter }
            _filter.tuntype = "all"
            if (type === 'page') {
              _filter.page = val
              if (val === 1) _filter.tap = 1
            } else {
              _filter.tap = val
            }
            setFilter(_filter)
          }}
          updateFilter={(val, type) => {
            let _filter = { ...filter }
            if (type === 'country') {
              _filter['view'] = 'country'
              _filter['province_name'] = 'ทั้งหมด'
              _filter['city_name'] = null
              _filter['tumbon_name'] = null
            } else if (type === 'province') {
              _filter['view'] = 'province'
              _filter['province_name'] = val
              _filter['city_name'] = null
              _filter['tumbon_name'] = null
            } else if (type === 'city') {
              _filter['view'] = 'city'
              _filter['city_name'] = val
              _filter['tumbon_name'] = null
            }
            setFilter(_filter)
          }}
        />
      </div>
    </Layout>
  )
}

export default FullMap
