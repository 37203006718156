import React, { useState } from 'react';
import Chart from '../../../../components/Chart'
const Overview = (props) => {
  return (
    <div>
      <p className='text-center text-base lg:text-lg font-baijam'>
        โครงการทุนเสมอภาคได้เริ่มช่วยเหลือนักเรียนตั้งแต่ปีการศึกษา 2561 จนถึงปัจจุบัน <br />
        โดยมีกลุ่มเป้าหมายเป็นเด็กนักเรียนในระดับการศึกษาผู้ขาดแคลนทุนทรัพย์ <br />
        โดยให้ความช่วยเหลือกลุ่มเป้าหมายไปแล้วกว่า 5,000,000 คน ครอบคลุมโรงเรียน 30,000 โรงเรียน ใน 6 สังกัด
      </p>
      <div className='grid grid-cols-12 gap-1'>
        <div className='col-span-12 lg:col-span-4 flex-col'>

          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <div className='absolute top-6 right-4 p-2 bg-[#3b69df] rounded-full border-solid border-8 border-[#e2e9fa]'>
              <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5348 4.8235C23.5332 4.8235 23.5317 4.8235 23.5301 4.8235H21.9531V4.28386C21.9616 4.07027 21.8234 3.87832 21.6181 3.81865C20.5233 3.53174 19.3959 3.38787 18.264 3.39066C16.4032 3.39066 13.8678 3.80935 11.993 5.7167C10.1322 3.79074 7.57821 3.39066 5.72204 3.39066C4.59019 3.38787 3.46276 3.53174 2.3679 3.81865C2.16263 3.87832 2.02446 4.07027 2.03295 4.28386V4.81885H0.465206C0.208296 4.81885 0 5.02714 0 5.28405V20.1427C0 20.3997 0.208296 20.608 0.465206 20.608C0.545106 20.6077 0.623609 20.5869 0.693158 20.5475C0.739678 20.5242 5.39174 17.9795 11.886 19.6171H11.9558H11.9977C12.0348 19.6215 12.0722 19.6215 12.1093 19.6171C18.6222 17.9656 23.2743 20.5056 23.3022 20.5475C23.4461 20.6306 23.6235 20.6306 23.7674 20.5475C23.9104 20.4649 23.999 20.3126 24 20.1474V5.28871C24 5.0318 23.7918 4.8235 23.5348 4.8235ZM0.930413 19.4031V5.75391H2.04226V17.4213C2.03935 17.6782 2.24526 17.8888 2.50217 17.8917C2.52729 17.892 2.55241 17.8903 2.57724 17.8865C3.53964 17.7396 4.51128 17.6618 5.48478 17.6539C6.8333 17.6305 8.17618 17.8333 9.45765 18.254C6.56377 17.9041 3.62844 18.2996 0.930413 19.4031ZM5.48478 16.7281C4.63863 16.7301 3.79347 16.7844 2.95406 16.891V4.65137C3.85982 4.44959 4.78476 4.34661 5.71274 4.34434C7.45261 4.34434 9.85307 4.74442 11.5185 6.62385L11.5325 18.2121C10.5648 17.5795 8.6761 16.7281 5.48478 16.7281ZM12.4629 6.62385C14.1283 4.76302 16.5288 4.34434 18.2687 4.34434C19.1966 4.34707 20.1215 4.45 21.0273 4.65137V16.891C20.1926 16.7834 19.3521 16.7275 18.5106 16.7235C15.3379 16.7235 13.4445 17.5748 12.4629 18.2075V6.62385ZM23.0649 19.4031C20.3612 18.2966 17.4191 17.901 14.5191 18.254C15.8066 17.8318 17.1559 17.6289 18.5106 17.6539C19.4902 17.6594 20.4681 17.7355 21.4367 17.8818C21.6907 17.9204 21.9279 17.7457 21.9665 17.4917C21.9702 17.4669 21.972 17.4418 21.9717 17.4166V5.75391H23.0835L23.0649 19.4031Z" fill="white" />
              </svg>
            </div>
            <div className='flex flex-col p-4'>
              <span className='text-xl font-bold'>จำนวนโรงเรียนที่ช่วยเหลือ</span>
              <span className='text-5xl font-bold text-[#3b69df]'>126,565 <span className='text-base text-gray-400'> โรงเรียน</span></span>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-[#9db4ef] my-4">
                <div className="bg-[#3b69df] h-2.5 rounded-full" style={{ width: "68%" }}></div>
              </div>
              <div className='grid grid-cols-2'>
                <div className='col-span-1 flex flex-col'>
                  <span className='text-lg font-bold'>จากโรงเรียนทั้งหมด</span>
                  <span className='text-3xl font-bold text-[#3b69df]'>126,565 <span className='text-base text-gray-400'> โรงเรียน</span></span>
                </div>
                <div className='col-span-1'>
                  <div className='w-full h-full rounded-lg flex flex-col shadow-xl text-center bg-[#e2e8fa]'>
                    <span className='m-auto text-left'>คิดเป็น</span>
                    <span className='m-auto text-4xl font-bold text-[#3b69df]'>67.58%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='relative w-full mt-4 rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <div className='absolute top-6 right-4 p-2 bg-[#089a55] rounded-full border-solid border-8 border-[#daf0e6]'>
              <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5348 4.8235C23.5332 4.8235 23.5317 4.8235 23.5301 4.8235H21.9531V4.28386C21.9616 4.07027 21.8234 3.87832 21.6181 3.81865C20.5233 3.53174 19.3959 3.38787 18.264 3.39066C16.4032 3.39066 13.8678 3.80935 11.993 5.7167C10.1322 3.79074 7.57821 3.39066 5.72204 3.39066C4.59019 3.38787 3.46276 3.53174 2.3679 3.81865C2.16263 3.87832 2.02446 4.07027 2.03295 4.28386V4.81885H0.465206C0.208296 4.81885 0 5.02714 0 5.28405V20.1427C0 20.3997 0.208296 20.608 0.465206 20.608C0.545106 20.6077 0.623609 20.5869 0.693158 20.5475C0.739678 20.5242 5.39174 17.9795 11.886 19.6171H11.9558H11.9977C12.0348 19.6215 12.0722 19.6215 12.1093 19.6171C18.6222 17.9656 23.2743 20.5056 23.3022 20.5475C23.4461 20.6306 23.6235 20.6306 23.7674 20.5475C23.9104 20.4649 23.999 20.3126 24 20.1474V5.28871C24 5.0318 23.7918 4.8235 23.5348 4.8235ZM0.930413 19.4031V5.75391H2.04226V17.4213C2.03935 17.6782 2.24526 17.8888 2.50217 17.8917C2.52729 17.892 2.55241 17.8903 2.57724 17.8865C3.53964 17.7396 4.51128 17.6618 5.48478 17.6539C6.8333 17.6305 8.17618 17.8333 9.45765 18.254C6.56377 17.9041 3.62844 18.2996 0.930413 19.4031ZM5.48478 16.7281C4.63863 16.7301 3.79347 16.7844 2.95406 16.891V4.65137C3.85982 4.44959 4.78476 4.34661 5.71274 4.34434C7.45261 4.34434 9.85307 4.74442 11.5185 6.62385L11.5325 18.2121C10.5648 17.5795 8.6761 16.7281 5.48478 16.7281ZM12.4629 6.62385C14.1283 4.76302 16.5288 4.34434 18.2687 4.34434C19.1966 4.34707 20.1215 4.45 21.0273 4.65137V16.891C20.1926 16.7834 19.3521 16.7275 18.5106 16.7235C15.3379 16.7235 13.4445 17.5748 12.4629 18.2075V6.62385ZM23.0649 19.4031C20.3612 18.2966 17.4191 17.901 14.5191 18.254C15.8066 17.8318 17.1559 17.6289 18.5106 17.6539C19.4902 17.6594 20.4681 17.7355 21.4367 17.8818C21.6907 17.9204 21.9279 17.7457 21.9665 17.4917C21.9702 17.4669 21.972 17.4418 21.9717 17.4166V5.75391H23.0835L23.0649 19.4031Z" fill="white" />
              </svg>
            </div>
            <div className='flex flex-col p-4'>
              <span className='text-xl font-bold'>จำนวนนักเรียนที่ช่วยเหลือ</span>
              <span className='text-5xl font-bold text-[#089a55]'>3,156,125 <span className='text-base text-gray-400'> คน</span></span>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-[#daf0e5] my-4">
                <div className="bg-[#089a55] h-2.5 rounded-full" style={{ width: "64%" }}></div>
              </div>
              <div className='grid grid-cols-2'>
                <div className='col-span-1 flex flex-col'>
                  <span className='text-lg font-bold'>จากนักเรียนทั้งหมด</span>
                  <span className='text-3xl font-bold text-[#089a55]'>4,927,228 <span className='text-base text-gray-400'> คน</span></span>
                </div>
                <div className='col-span-1'>
                  <div className='w-full h-full rounded-lg flex flex-col shadow-xl text-center bg-[#daf0e6]'>
                    <span className='m-auto text-left'>คิดเป็น</span>
                    <span className='m-auto text-4xl font-bold text-[#089a55]'>64.05%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='col-span-12 lg:col-span-8'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
            <Chart
              className='p-4'
              options={{
                type: 'areaspline',
                title: 'จำนวนนักเรียนที่ได้รับการช่วยเหลือ',
                categories: ['2/2560', '1/2561', '2/2561', '1/2562', '2/2562', '1/2563', '2/2563', '1/2564'],
                yAxis:{
                  title : {
                    text : 'จำนวนนักเรียน (คน)',
                  }
                },
                legend: {
                  align: 'center',
                  verticalAlign: 'bottom',
                  x: 0,
                  y: 0
                },
              }}
              data={
                [{
                  name: 'นร.ทุนเสมอภาค',
                  marker: {
                    symbol: 'square'
                  },
                  data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2,26.5],
                  color : '#ffb280'
                }]
              } />
          </div>
        </div>
        <div className='col-span-12 lg:col-span-4 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200'>
              
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
