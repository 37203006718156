import React, { useState ,useEffect} from 'react';
import '../../index.css';
import { Select } from 'antd';

const { Option } = Select;

const FilterMap = (props) => {
  const [toptype, setTopType] = useState('all')
  const [tuntype, setTunType] = useState('all')

  useEffect(async () => {
    setTunType(props.tuntype)
  }, [props.tuntype])

  const onClickTunTop = (value) => {
    props.onClickTunTop(value)
    setTopType(value)
  }

  const onChangeTunType = (value) => {
    props.onChangeTunType(value)
    setTunType(value)
  }

  return (
    <div className={'w-[330px] h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-6 ' + props?.className}>
      {/* <span className='font-bold text-lg mx-1 mb-2'>เลือกการแสดงผลจังหวัด</span>
      <div className='flex flex-inline'>
        <button className={`rounded-md ${toptype === 'all' ? 'bg-[#37CA72]' : 'bg-[#9D9D9D]'} text-white px-3 py-2 m-1 text-center`} onClick={() => onClickTunTop('all')}>ทั้งหมด</button>
        <button className={`rounded-md ${toptype === 'top' ? 'bg-[#37CA72]' : 'bg-[#9D9D9D]'} text-white px-3 py-2 m-1 text-center`} onClick={() => onClickTunTop('top')}>สูงสุด 10 อันดับ</button>
        <button className={`rounded-md ${toptype === 'bottom' ? 'bg-[#37CA72]' : 'bg-[#9D9D9D]'} text-white px-3 py-2 m-1 text-center`} onClick={() => onClickTunTop('bottom')}>ต่ำสุด 10 อันดับ</button>
      </div> */}
      <span className='font-bold text-lg mx-1 mb-2'>เลือกโครงการ</span>
      <Select onChange={onChangeTunType} value={tuntype}>
        {
          props.filter.page === 1 ?
            (
              <>
                <Option value="all">ทั้งหมด</Option>
                <Option value="exjon">ทุนเสมอภาค</Option>
                <Option value="krt">ครูรัก(ษ์)ถิ่น</Option>
                <Option value="vec">นวัตกรรมสายอาชีพ</Option>
                <Option value="tps">ทุนพระกนิษฐา</Option>
                <Option value="target_group">ทุนพัฒนาอาชีพ</Option>
              </>
            ) : (
              <>
                <Option value="all">ทั้งหมด</Option>
                <Option value="province20">เด็กที่ไม่อยู่ในระบบ 20 จังหวัด</Option>
                <Option value="plan_teaher">แผนครูและเด็กนอกระบบ</Option>
                <Option value="abe">เด็กปฐมวัย</Option>
                <Option value="tsqp">TSQP</Option>
              </>
            )

        }
      </Select>
    </div>
  )
}

export default FilterMap
